export default function Observer() {
	const observerOptions = {
		root: null,
		threshold: 0,
		rootMargin: '0px'
	};

	const observer = new IntersectionObserver(entries => {
		entries.forEach(entry => {
			if (entry.isIntersecting) {
				entry.target.classList.add('in-view');
				observer.unobserve(entry.target);
			}
		});
	}, observerOptions);

	window.addEventListener('DOMContentLoaded', (event) => {

		const sections = Array.from(document.getElementsByClassName('js-observer'));

		for (let section of sections) {
			observer.observe(section);
		}

	});

}