import $ from 'jquery';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'slick-carousel';

export default function SlickCarousel() {
	$(() => {
		$('.donor-carousel').slick({
			infinite: true,
			slidesToShow: 4,
			slidesToScroll: 1,
			dots: true,
			arrows: true,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 1,
						infinite: true,
						dots: true,
						arrows: false
					}
				},
				{
					breakpoint: 800,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1,
						dots: true,
						arrows: false
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						dots: true,
						arrows: false
					}
				},
			]
		})


		$('.hero-homepage__carousel-slider').slick({
			infinite: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			dots: true,
			arrows: true,
		})

		$('.video-slider').slick({
			infinite: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: true,
			centerMode: true,
			centerPadding: '20%',
			responsive: [
				{
					breakpoint: 768,
					settings: {
						centerMode: false,
					}
				}
			]
		}).on('beforeChange', (event, slick, currentSlide, nextSlide) => {
			if (currentSlide !== nextSlide) {
				document.querySelectorAll('.slick-center + .slick-cloned').forEach((next) => {
					// timeout required or Slick will overwrite the classes
					setTimeout(() => next.classList.add('slick-current', 'slick-center'));
				});
			}
		});

	})
}