require('alpinejs');
import ReactComponentLoader from './classes/ReactComponentLoader';
import $ from 'jquery';
import Josh from 'joshjs';
import Plyr from "plyr";

ReactComponentLoader({
	'loadingIndicator': require('./components/LoadingIndicator').default,
})

const josh = new Josh();


/** Vanilla JS components */
new (require('./components/SiteHeader').default);
new (require('./components/SlickCarousel').default);
new (require('./components/Observer').default);



$('.video-slider').on('init', function(event, slick){
    const players = Array.from(document.querySelectorAll('.js-player')).map(p => new Plyr(p));
});
