import Utils from "../classes/Utils";

/**
 * Handles dynamic states for mobile/desktop header
 *
 * NOTE: some dynamic functionality (i.e. mobile menu
 * toggle) is handled via AlpineJS directly in the
 * `site-header` view.
 */
export default class SiteHeader {

    constructor() {

        this.siteHeader = document.querySelector('.site-header');

        if (!this.siteHeader) return

        this.mobileMenuToggle = document.querySelector('.js-mobile-menu-toggle');
        this.mobileSubmenus = document.querySelectorAll('#menu-main-menu > .menu-item-has-children');

        // add all event listeners
        this.addMobileToggleListener();
        this.addMobileSubmenuListener();
    }

    addMobileToggleListener() {
        this.mobileMenuToggle.addEventListener('click', (event) => {
            this.siteHeader.classList.toggle('js-mobile-menu-active')
        })
    }

    addMobileSubmenuListener() {
        this.mobileSubmenus.forEach((el) => {
            el.addEventListener('click', (event) => {
                if(!event.target.closest('.sub-menu')) {
                    event.preventDefault();
                    el.classList.toggle('_open')
                }
            })
        })
    }

}
